import { createSvgIcon } from '@mui/material';

const UKFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1064_15120)'>
      <mask id='mask0_1064_15120' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='12'>
        <path d='M0 0V12H18V0H0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1064_15120)'>
        <path d='M0 0V12H18V0H0Z' fill='#012169' />
        <path d='M0 0L18 12L0 0ZM18 0L0 12L18 0Z' fill='black' />
        <path d='M0 0L18 12M18 0L0 12' stroke='white' strokeWidth='2.4' />
        <mask id='mask1_1064_15120' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='12'>
          <path d='M9 6H18V12L9 6ZM9 6V12H0L9 6ZM9 6H0V0L9 6ZM9 6V0H18L9 6Z' fill='white' />
        </mask>
        <g mask='url(#mask1_1064_15120)'>
          <path d='M0 0L18 12L0 0ZM18 0L0 12L18 0Z' fill='black' />
          <path d='M0 0L18 12M18 0L0 12' stroke='#C8102E' strokeWidth='1.6' />
        </g>
        <path d='M9 0V12V0ZM0 6H18H0Z' fill='black' />
        <path d='M9 0V12M0 6H18' stroke='white' strokeWidth='4' />
        <path d='M9 0V12V0ZM0 6H18H0Z' fill='black' />
        <path d='M9 0V12M0 6H18' stroke='#C8102E' strokeWidth='2.4' />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1064_15120'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'UKFlagIcon'
);

export default UKFlagIcon;
