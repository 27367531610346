export const ariaDownloadSourceMapping: any = {
  guidance: 'FDA Guidance',
  adcomm: 'Advisory Committee Documents',
  uk: 'NICE Documents',
  ct: 'Clinical Trial Documents',
  us: 'US FDA - Drugs & Biologics',
  eu: 'EU EMA - Drugs & Biologics',
  'us-devices': 'US FDA - Devices',
  'ema-pips': 'EMA PIPs',
  'ema-guidance': 'EMA Guidance',
  'fda-written-request': 'FDA WRs',
  ca: 'CA HPFB - Drugs & Biologics',
  chmp: 'CHMP Documents',
  hpra: 'IE HPRA - Drugs & Biologics',
  hma: 'EU HMA - Drugs & Biologics',
  'canada-guidance': 'CA Guidance Documents',
  'canada-consultation': 'CA Consultations Documents',
  jp: 'JP PMDA - Drugs & Biologics',
  'fda-letters': 'FDA Warnings and Untitled Letters',
  'dd-tools': 'Drug Development Tools',
  euctr: 'EU Clinical Trials Register',
  ema_orphan: 'EMA Orphan Designations',
  au: 'AU TGA - Drugs & Biologics',
  pdco: 'EU PDCO',
  mhra: 'UK MHRA - Drugs & Biologics',
  prac: 'EU PRAC',
  sec: 'SEC Edgar'
};

export const usMappings = {
  devices: 'us-devices',
  wr: 'fda-written-request',
  adcomm: 'adcomm',
  guidance: 'guidance',
  sba: 'us',
  'fda-letters': 'fda-letters',
  'dd-tools': 'dd-tools'
};

export const euMappings = {
  hma: 'hma',
  epar: 'eu',
  pip: 'ema-pips',
  guidance: 'ema-guidance',
  chmp: 'chmp',
  euctr: 'euctr',
  ema_orphan: 'ema_orphan',
  pdco: 'pdco',
  prac: 'prac'
};

export const secMapping = {
  'sec-edgar': 'sec'
};
export const caMapping = {
  hpfb: 'ca',
  guidance: 'canada-guidance',
  consultation: 'canada-consultation'
};

export const auMapping = {
  tga: 'au'
};

export const ukMapping = {
  mhra: 'mhra',
  nice: 'uk'
};

const processMappings = (appldata: any, key: any, mappings: any, sourceList: any) => {
  Object.entries(mappings).forEach(([subKey, value]: any) => {
    if (appldata[key].includes(subKey)) {
      sourceList.push({ label: ariaDownloadSourceMapping[value], value });
    }
  });
};

const getSourceList = (resultsState: any, globalState: any) => {
  const appldata: any = resultsState.decryptedSource;
  const sourceList: any = [];
  if (Object.keys(appldata).length !== 0) {
    Object.keys(appldata).forEach((key: string) => {
      const mappingKey = key === 'ie' ? 'hpra' : key;

      if (key === 'us') {
        processMappings(appldata, key, usMappings, sourceList);
      } else if (key === 'eu') {
        processMappings(appldata, key, euMappings, sourceList);
      } else if (key === 'ca') {
        processMappings(appldata, key, caMapping, sourceList);
      } else if (key === 'sec') {
        processMappings(appldata, key, secMapping, sourceList);
      } else if (key === 'au') {
        processMappings(appldata, key, auMapping, sourceList);
      } else if (key === 'uk') {
        processMappings(appldata, key, ukMapping, sourceList);
      } else {
        sourceList.push({ label: ariaDownloadSourceMapping[mappingKey], value: mappingKey });
      }
    });
  } else {
    sourceList.push({
      label: ariaDownloadSourceMapping[globalState.module],
      value: globalState.module
    });
  }

  return sourceList;
};

export default getSourceList;
