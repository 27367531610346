import { cloneDeep, isArray } from 'lodash';
import {
  CA_FILTERS,
  CDRH_FILTERS,
  CT_FILTERS_REG360,
  ENTITY_DATA_MAPPING,
  EU_FILTERS,
  EUA_FILTERS,
  HMA_FILTERS,
  HPRA_FILTERS,
  JP_FILTERS,
  US_FILTERS,
  AU_FILTERS,
  MHRA_FILTERS
} from './constants';
import { sortFilterCategoriesOptions } from '../../../components/NewFilters/utils';

export const getDefaultFilters = (source: string = 'us') => {
  if (source?.toLowerCase() === 'us') {
    return US_FILTERS;
  }
  if (source?.toLowerCase() === 'eu') {
    return EU_FILTERS;
  }
  if (source?.toLowerCase() === 'ct') {
    return CT_FILTERS_REG360;
  }
  if (source?.toLowerCase() === 'ca') {
    return CA_FILTERS;
  }
  if (source?.toLowerCase() === 'pma') {
    return CDRH_FILTERS;
  }
  if (source?.toLowerCase() === 'pmn') {
    return CDRH_FILTERS;
  }
  if (source?.toLowerCase() === 'denovo') {
    return CDRH_FILTERS;
  }
  if (source?.toLowerCase() === 'eua') {
    return EUA_FILTERS;
  }
  if (source?.toLowerCase() === 'hpra') {
    return HPRA_FILTERS;
  }
  if (source?.toLowerCase() === 'hma') {
    return HMA_FILTERS;
  }
  if (source?.toLowerCase() === 'jp') {
    return JP_FILTERS;
  }
  if (source?.toLowerCase() === 'au') {
    return AU_FILTERS;
  }
  if (source?.toLowerCase() === 'mhra') {
    return MHRA_FILTERS;
  }
  return [];
};

const getFieldValues = (row: any, key: string) => {
  if (isArray(row[key])) {
    return row[key];
  }
  return [row[key]];
};

const getFilters = (apiData: any, source: string = 'us') => {
  const filters = cloneDeep(
    getDefaultFilters(source).map((fltr: { [key: string]: any }) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );

  apiData.forEach((row: any) => {
    filters.forEach((filter: { [key: string]: any }) => {
      const uniqueValuesSet = new Set(filter.options || []);
      getFieldValues(row, filter.value).forEach((val: string | number) => {
        if (val) {
          uniqueValuesSet.add(val);
        }
      });

      const formattedOptions = Array.from(uniqueValuesSet);
      const sortedOptions = formattedOptions.sort((a: any, b: any) =>
        sortFilterCategoriesOptions(a, b)
      );

      // eslint-disable-next-line no-param-reassign
      filter.options = sortedOptions;
    });
  });

  return filters;
};

export const mapGraphEntityData = (data: { [key: string]: string | number } | null) => {
  const mappedData: { [key: string]: string | number } = {};
  if (data) {
    Object.keys(ENTITY_DATA_MAPPING).forEach((key: string) => {
      mappedData[ENTITY_DATA_MAPPING[key]?.name || key] =
        ENTITY_DATA_MAPPING[key]?.getValue?.(data[key]) || data[key];
    });
  }
  return mappedData;
};

export const getPhaseInTrials = (phase: string) => {
  if (/\d/.test(phase)) {
    return phase?.replace(/Phase/g, 'P').replace(/ /g, '');
  }
  return 'NA';
};

export const getApplicationType = (
  sourceType: string,
  applicationDetails: { [key: string]: any }
) => {
  if (sourceType?.toLowerCase() === 'us-devices') {
    return applicationDetails?.application_type.toLowerCase();
  }
  if (sourceType?.toLowerCase() === 'us') {
    if (applicationDetails?.application_type?.toLowerCase() === 'eua') {
      return applicationDetails?.application_type.toLowerCase();
    }
    return sourceType;
  }
  return sourceType;
};

export default getFilters;
