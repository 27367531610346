const getApplicationSource = (source: Record<string, any>): string => {
  if (source?.eu && source?.eu?.includes('hma')) {
    return 'hma';
  }
  if (source?.ie && source?.ie?.includes('hpra')) {
    return 'hpra';
  }
  if (source?.eu) {
    return 'eu';
  }
  if (source?.us && source?.us?.includes('devices')) {
    return 'us-devices';
  }
  if (source?.us) {
    return 'us';
  }
  if (source?.jp) {
    return 'jp';
  }
  if (source?.ca) {
    return 'ca';
  }
  if (source?.au) {
    return 'au';
  }
  if (source?.uk && source?.uk?.includes('mhra')) {
    return 'mhra';
  }
  return 'us';
};

export default getApplicationSource;
