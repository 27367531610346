import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface MHRAAPIApps {
  approval_date: string;
  approval_year: string;
  atc_classification: Array<any>;
  atc_code: Array<string>;
  document_count: 3;
  document_total_page_count: 23;
  formulation: string | Array<string>;
  generic_name: string | Array<string>;
  inProjects: Array<any>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  marketing_flag: string;
  marketing_text: string;
  matched_synonyms: Array<string>;
  product_name: string;
  product_number: string;
  products: Array<any>;
  route: string | Array<string>;
  source_index: string;
  sponsor_name: string;
  vin: string;
}

export const MHRA_IDENTIFIER_KEY = 'vin';

const mapMHRAApps = (apiData: MHRAAPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'mhra');
  const cardProps: CardData = {
    source: 'mhra',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: titleCase(
      (Array.isArray(apiData.generic_name)
        ? apiData.generic_name.join(' ')
        : apiData.generic_name) || 'Unknown'
    ),
    // using application_number as fallback for vin (used for reg360 header popup card)
    identifier: apiData?.vin ?? (apiData as any)?.application_number,
    showDiscontinued: false,
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    labels: ['MHRA'],
    colorScheme: apiData?.source_index,
    approvalTracks,
    ...apiData
  };
  return cardProps;
};

export default mapMHRAApps;
