// @ts-nocheck
import React, { useContext, useState } from 'react';

// muiv5
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { MenuList, Popover } from '@mui/material';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import AriaStore from '../../../store/Aria';
import AriaActions from '../../../store/Aria/actions';

// constants
import { DROPDOWN_MENU_OPTIONS } from '../utils/constants';
import handleDownload from '../utils/exportResults';
import getSourceList_, {
  usMappings,
  caMapping,
  euMappings,
  ukMapping,
  ariaDownloadSourceMapping
} from '../../SearchResults/utils/getSourceList';

const CollapsableList = ({
  downloadOption,
  expandedOptionsOpen,
  resultsState
}: {
  downloadOption: string;
  expandedOptionsOpen: boolean;
  resultsState: any;
}) => {
  // global context store
  const { state, dispatch } = useContext(GlobalStore) as any;
  const [showDropDown, setShowDropDown] = useState(false);
  // aria context store
  const { ariaState, ariaDispatch } = useContext(AriaStore) as any;
  // url parameters
  const { module } = { module: 'core' };
  const [openSourceList, setOpenSourceList] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [downloadItem, setDowloadItem] = useState();
  const [source, setSource] = useState('us');

  /**
   * function to perform action on aria results and suggested answers
   * @param {object} e event handler
   * @param {string} type action type (download or sort)
   * @param {object} selectedDropdown action menu options
   */

  const getDataSource = data => {
    let dataSource = data.normalized_source === 'ie' ? 'hpra' : data.normalized_source;
    if (data.normalized_source === 'us') {
      dataSource = usMappings[data.normalized_region];
    } else if (data.normalized_source === 'eu') {
      dataSource = euMappings[data.normalized_region];
    } else if (data.normalized_source === 'ca') {
      dataSource = caMapping[data.normalized_region];
    } else if (data.normalized_source === 'ie') {
      dataSource = 'hpra';
    } else if (data.normalized_source === 'uk') {
      dataSource = ukMapping[data.normalized_region];
    }
    return dataSource;
  };

  const validSources = resultsState?.documentResults?.results?.map(data => {
    return getDataSource(data);
  });

  const getNoramlizedResults = sourceValue => {
    const formattedResults = [];
    resultsState?.documentResults?.results.forEach(data => {
      const dataSource = getDataSource(data);
      if (sourceValue === dataSource) {
        formattedResults.push(data);
      }
    });
    return formattedResults;
  };

  const handleMenuSubmit = async (
    sourceValue?: string,
    type: any,
    selectedDropdown: any,
    fileHeader: any
  ) => {
    let res = resultsState?.documentResults?.results;
    res = getNoramlizedResults(sourceValue);
    if (!res || res.length < 1) {
      dispatch({
        type: GlobalActions.SET_ALERT
      });
    }
    let suggestedResults = resultsState?.documentResults?.suggestedAns;
    if (type === 'download') {
      try {
        handleDownload(
          selectedDropdown.value,
          res,
          sourceValue,
          module,
          fileHeader,
          resultsState.documentResults.results
        );
      } catch (err) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Something went wrong' }
        });
      }
    }

    if (type === 'sort') {
      await ariaDispatch({ type: AriaActions.SCROLL_TO_TOP, value: true });
      // if the clicked sort is already selected then replace with the original results
      // which was in order of score confidence
      if (ariaState.selectedSort === selectedDropdown.id || selectedDropdown.value === 'default') {
        const sortOrder = ['VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW']; // custom order for sorting
        res = res.sort(
          (a, b) => sortOrder.indexOf(a.score_confidence) - sortOrder.indexOf(b.score_confidence)
        );
        suggestedResults = suggestedResults.sort(
          (a, b) => sortOrder.indexOf(a.score_confidence) - sortOrder.indexOf(b.score_confidence)
        );
        await ariaDispatch({
          type: AriaActions.SET_SELECTED_SORT,
          value: 'Relevance: Very High -> Low'
        }); // remove the selected value for reset of dropdown
      } else {
        // if there was nothing selected sort by sortType
        await ariaDispatch({ type: AriaActions.SET_SELECTED_SORT, value: selectedDropdown.id });
        if (selectedDropdown.sortType === 'asc') {
          res = res.sort((a, b) =>
            a[selectedDropdown.value] > b[selectedDropdown.value] ? 1 : -1
          );
          suggestedResults = suggestedResults.sort((a, b) =>
            a[selectedDropdown.value] > b[selectedDropdown.value] ? 1 : -1
          );
        } else {
          res = res.sort((a, b) =>
            b[selectedDropdown.value] > a[selectedDropdown.value] ? 1 : -1
          );
          suggestedResults = suggestedResults.sort((a, b) =>
            b[selectedDropdown.value] > a[selectedDropdown.value] ? 1 : -1
          );
        }
      }
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /**
   * @description This method creates the file title information to add to the excel file.
   */
  const generateDownloadFileTitle = (value, sourceValue) => {
    const line0 = value === 'semicolonFile' ? 'sep=;' : '';
    const line1 = 'Content Information';
    const line2 = `You Asked RIA: ${resultsState.decryptedPayload.query}`;
    const line3 = `Showing search results ${ariaDownloadSourceMapping[sourceValue] || ''}`;
    return [line0, line1, line2, line3];
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowDropDown(true);
    setAnchorEl(event.currentTarget);
    setOpenSourceList(true);
  };

  const getSourceList = () => {
    return getSourceList_(resultsState, state);
  };
  const handleSourceClick = sourceValue => {
    setSource(sourceValue);
    handleMenuSubmit(
      sourceValue,
      downloadOption,
      downloadItem,
      generateDownloadFileTitle(downloadItem.value, sourceValue)
    );
  };

  return (
    <>
      {DROPDOWN_MENU_OPTIONS[downloadOption] &&
        DROPDOWN_MENU_OPTIONS[downloadOption][source]?.map((item: any) => (
          <MenuItem
            disabled={false}
            onClick={e => {
              handleClick(e);
              setDowloadItem(item);
            }}
            sx={{
              color: 'black.main'
            }}
            key={item.id}>
            <ListItemText primary={item.id} />
          </MenuItem>
        ))}
      {showDropDown ? (
        <Popover
          open={openSourceList}
          anchorEl={anchorEl}
          onClose={() => {
            setOpenSourceList(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <MenuList>
            {getSourceList()?.map((item: any) =>
              validSources?.indexOf(item.value) > -1 ? (
                <MenuItem
                  key={item.value}
                  onClick={() => {
                    handleSourceClick(item.value);
                  }}>
                  {item.label}
                </MenuItem>
              ) : null
            )}
          </MenuList>
        </Popover>
      ) : null}
    </>
  );
};

export default CollapsableList;
