export const TIMELINE_INFO: { [key: string]: any } = {
  us: {
    approval_history: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    },
    commission_procedures: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    },
    rems: {
      dotLabel: 'version_id'
    }
  },
  eu: {
    approval_history: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    },
    commission_procedures: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    },
    rems: {
      dotLabel: 'version_id'
    }
  },
  ct: {
    approval_history: {
      dotLabel: 'version',
      dotField: 'version',
      dotFieldCondition: 'None'
    }
  },
  ca: {
    approval_history: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  jp: {
    approval_history: {
      dotField: 'submission_type',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  pma: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  pmn: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  denovo: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  eua: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  hpra: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  hma: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  },
  au: {
    approval_history: {
      dotField: 'variation_number',
      dotFieldCondition: 'ORIG',
      dotLabel: 'variation_number'
    }
  },
  mhra: {
    approval_history: {
      dotField: 'submission_num',
      dotFieldCondition: 'ORIG',
      dotLabel: 'submission_num'
    }
  }
};

export const TABNAME_MATCHES: { [key: string]: any } = {
  commission_procedures: 'commissionProcedures',
  approval_history: 'approvalHistory'
};

export const TIMELINE_HEADERS: { [key: string]: any } = {
  us: {
    approval_stacked_list: [
      { label: 'Application Number', key: 'appl_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Submission Class', key: 'description' },
      { label: 'Review Office', key: 'office' },
      { label: 'Change', key: 'change' },
      { label: 'Approval Time', key: 'approval_time' },
      { label: 'Advisory Committee', key: 'advisory_committee.adcomm_flag' },
      { label: 'Date of Advisory Committee', key: 'advisory_committee.adcomm_meeting_start_date' },
      { label: 'Pivotal Studies', key: 'nct_id' },
      { label: 'Accelerated Approval', key: 'accelerated_approval_text' },
      { label: 'Breakthrough', key: 'breakthrough_text' },
      { label: 'Complete Response', key: 'complete_response_text' },
      { label: 'Fast Track', key: 'fast_track_text' },
      { label: 'Orphan Status', key: 'orphan_status_text' },
      { label: 'Review Priority', key: 'review_status' },
      { label: 'RTOR', key: 'rtor_text' },
      { label: 'Pediatric Use', key: 'pediatric_use' },
      { label: 'Pediatric Terms', key: 'pediatric_terms_matching' },
      { label: 'REMS', key: 'rems_text' },
      { label: 'Boxed Warning', key: 'submission_boxed_warning_text' },
      { label: 'Indications', key: 'indications_text' },
      { label: 'Label', key: 'labels' },
      { label: 'letter', key: 'letter' },
      { label: 'Review', key: 'review' }
    ],
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Description', key: 'description' },
      { label: 'Review Office', key: 'office' },
      { label: 'Change', key: 'change' },
      { label: 'Approval Time', key: 'approval_time' },
      { label: 'Indications', key: 'indications_text' },
      { label: 'Advisory Committee', key: 'advisory_committee.adcomm_flag' },
      { label: 'Date of Advisory Committee', key: 'advisory_committee.adcomm_meeting_start_date' },
      { label: 'Pivotal Studies', key: 'nct_id' },
      { label: 'Accelerated Approval', key: 'accelerated_approval_text' },
      { label: 'Breakthrough Designation', key: 'breakthrough_text' },
      { label: 'Complete Response', key: 'complete_response_text' },
      { label: 'Fast Track', key: 'fast_track_text' },
      { label: 'Orphan Status', key: 'orphan_status_text' },
      { label: 'RTOR', key: 'rtor_text' },
      { label: 'Review Priority', key: 'review_status' },
      { label: 'Pediatric Use', key: 'pediatric_use' },
      { label: 'Pediatric Terms', key: 'pediatric_terms_matching' },
      { label: 'REMS', key: 'rems_text' },
      { label: 'Boxed Warning', key: 'submission_boxed_warning_text' }
    ]
  },
  eu: {
    approval_stacked_list: [
      { label: 'Source', key: 'source' },
      { label: 'Product Number', key: 'product_number' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'CHMP to EC', key: 'delta' },
      { label: 'CHMP Date', key: 'chmp_date' },
      { label: 'Rapporteur', key: 'rapporteur' },
      { label: 'Co-Rapporteur', key: 'co_rapporteur' },
      { label: 'Indications', key: 'indications' },
      { label: 'Pediatric Use', key: 'pediatric_use' },
      { label: 'Pediatric Terms', key: 'pediatric_terms_matching' },
      { label: 'Change', key: 'change' },
      { label: 'Review', key: 'review' },
      { label: 'Label', key: 'labels' }
    ],
    commission_procedures: [
      { label: 'Product Number', key: 'product_number' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Change', key: 'change' }
    ],
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Rapporteur', key: 'rapporteur' },
      { label: 'Co-Rapporteur', key: 'co_rapporteur' },
      { label: 'Change', key: 'change' }
    ]
  },
  ct: {
    approval_history: [
      { label: 'Version', key: 'version' },
      { label: 'Submitted Date', key: 'submitted_date' },
      { label: 'Changes', key: 'changes' }
    ],
    approval_stacked_list: [
      { label: 'Version', key: 'version' },
      { label: 'Submitted Date', key: 'submitted_date' },
      { label: 'Changes', key: 'changes' }
    ]
  },
  ca: {
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Class Descriptor', key: 'submission_class_descriptor' },
      { label: 'Submission Activity', key: 'summ_activity' },
      { label: 'Decision', key: 'decision' },
      { label: 'Purpose', key: 'purpose' },
      { label: 'NOC with Condition', key: 'noc_w_condition_flag_text' },
      { label: 'Priority Review', key: 'priority_review_flag_text' }
    ],
    approval_stacked_list: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Submission Class Descriptor', key: 'submission_class_descriptor' },
      { label: 'Submission Activity', key: 'summ_activity' },
      { label: 'Decision', key: 'decision' },
      { label: 'Purpose', key: 'purpose' },
      { label: 'NOC with Condition', key: 'noc_w_condition_flag_text' },
      { label: 'Priority Review', key: 'priority_review_flag_text' },
      { label: 'Product Monograph', key: 'product_monogrpah' }
    ],
    clinical_information_details: [
      { label: 'VIN', key: 'vin' },
      { label: 'Brand Name', key: 'trade_name' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Active Ingredients', key: 'active_ingredients' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Decision Date', key: 'decision_date' }
    ]
  },
  'us-devices': {
    approval_stacked_list: [
      { label: 'Application Number', key: 'application_number' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Date of Received', key: 'date_received' },
      { label: 'Decision Date', key: 'decision_date' },
      { label: 'Submission Reason', key: 'submission_reason' },
      { label: 'Attributes', key: 'attributes' },
      { label: 'Authorized Setting', key: 'authorized_setting' },
      { label: 'Precode Assigned', key: 'precode_assigned' },
      { label: 'Expedited Review', key: 'expedited_review_flag' },
      { label: 'Combination Product', key: 'combination_product' },
      { label: 'Third Party', key: 'third_party_flag' },
      { label: 'GMP Exempt', key: 'gmp_exempt_flag' },
      { label: 'Implant', key: 'implant_flag' },
      { label: 'Life Sustain Support', key: 'life_sustain_support_flag' },
      { label: 'Label', key: 'labels' },
      { label: 'letter', key: 'letter' },
      { label: 'Review', key: 'review' },
      { label: 'Summary', key: 'summary' },
      { label: 'Approval Order', key: 'approval_order' },
      { label: 'Other', key: 'other' }
    ]
  },
  pma: {
    approval_history: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Date of Received', key: 'date_received' },
      { label: 'Decision Date', key: 'decision_date' },
      { label: 'Submission Reason', key: 'submission_reason' },
      { label: 'Expedited Review', key: 'expedited_review_flag' },
      { label: 'Combination Product', key: 'combination_product' },
      { label: 'Third Party', key: 'third_party_flag' },
      { label: 'GMP Exempt', key: 'gmp_exempt_flag' },
      { label: 'Implant', key: 'implant_flag' },
      { label: 'Life Sustain Support', key: 'life_sustain_support_flag' }
    ]
  },
  pmn: {
    approval_history: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Date of Received', key: 'date_received' },
      { label: 'Decision Date', key: 'decision_date' },
      { label: 'Submission Reason', key: 'submission_reason' },
      { label: 'Expedited Review', key: 'expedited_review_flag' },
      { label: 'Combination Product', key: 'combination_product' },
      { label: 'Third Party', key: 'third_party_flag' },
      { label: 'GMP Exempt', key: 'gmp_exempt_flag' },
      { label: 'Implant', key: 'implant_flag' },
      { label: 'Life Sustain Support', key: 'life_sustain_support_flag' }
    ]
  },
  denovo: {
    approval_history: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Date of Received', key: 'date_received' },
      { label: 'Decision Date', key: 'decision_date' },
      { label: 'Submission Reason', key: 'submission_reason' },
      { label: 'Expedited Review', key: 'expedited_review_flag' },
      { label: 'Combination Product', key: 'combination_product' },
      { label: 'Third Party', key: 'third_party_flag' },
      { label: 'GMP Exempt', key: 'gmp_exempt_flag' },
      { label: 'Implant', key: 'implant_flag' },
      { label: 'Life Sustain Support', key: 'life_sustain_support_flag' }
    ]
  },
  eua: {
    approval_stacked_list: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Last Updated Date', key: 'last_updated_date' },
      { label: 'Attributes', key: 'attributes' },
      { label: 'Authorized Setting', key: 'authorized_setting' },
      { label: 'Precode Assigned', key: 'precode_assigned' },
      { label: 'Label', key: 'labels' },
      { label: 'Review', key: 'review' }
    ],
    approval_history: [
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Last Updated Date', key: 'last_updated_date' },
      { label: 'Attributes', key: 'attributes' },
      { label: 'Authorized Setting', key: 'authorized_setting' },
      { label: 'Precode Assigned', key: 'precode_assigned' }
    ]
  },
  hpra: {
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' }
    ],
    approval_stacked_list: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' },
      { label: 'Documents', key: 'documents' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Product Number', key: 'product_number' }
    ]
  },
  hma: {
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' }
    ],
    approval_stacked_list: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' },
      { label: 'Documents', key: 'documents' },
      { label: 'Submission Number', key: 'submission_num' },
      { label: 'Product Number', key: 'product_number' }
    ]
  },
  jp: {
    approval_history: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Package Insert Number', key: 'package_insert_number' },
      { label: 'Indications', key: 'indications_and_usage' },
      { label: 'Remarks', key: 'remarks' }
    ],
    approval_stacked_list: [
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Submission Type', key: 'submission_type' },
      { label: 'Package Insert Number', key: 'package_insert_number' },
      { label: 'Indications', key: 'indications_and_usage' },
      { label: 'Remarks', key: 'remarks' }
    ]
  },
  au: {
    approval_history: [
      { label: 'Approval Date', key: 'revision_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Indications', key: 'indications_and_usage' }
    ],
    approval_stacked_list: [
      { label: 'Approval Date', key: 'revision_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Indications', key: 'indications_and_usage' }
    ]
  },
  mhra: {
    approval_history: [
      { label: 'Vin Number', key: 'application_number' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' }
    ],
    approval_stacked_list: [
      { label: 'Vin Number', key: 'application_number' },
      { label: 'Approval Date', key: 'approval_date' },
      { label: 'Generic Name', key: 'generic_name' },
      { label: 'Product Name', key: 'product_name' },
      { label: 'Sponsor Name', key: 'sponsor_name' },
      { label: 'Category', key: 'category_bucket' },
      { label: 'Title', key: 'document_title' },
      { label: 'Documents', key: 'documents' }
    ]
  }
};

export default TIMELINE_INFO;
